@import "../../../Variables.scss";

.card{
    border: 2px $accentColor ridge;
    box-shadow: 0 2px 8px $accentColorDimmed;
}
.cardRounded{
    border-radius: .5em;
}
.cardRoundedModerate{
    border-radius: .75em;
}
.cardRoundedHeavy{
    border-radius: 1em;
}
.cardHover:hover {
    box-shadow: 0 2px 8px $accentColorDimmed;
    color: $accentColor;
}
.primary{
    background-color: $primaryColor;
    color: $fontColor;
}
.altPrimary{
    background-color: $altPrimaryColor;
    color: $fontColor;
}
.none{
    background-color: transparent;
    color: $fontColor;
}