.navigationLinks {
  font-size: 1.5em !important;
  background-color: #6c757d !important;
  color: #ffffff !important;
}

.navigationLinks:hover {
  font-weight: bolder;
  background-color: #50555a !important;
  color: #23e6ef !important;
  border: 1px #23e6ef solid;
}

.linkFontFamily {
  font-family: "Shadows Into Light Two", cursive;
}/*# sourceMappingURL=NavigationLink.module.css.map */