.footerWrapper {
  text-align: center;
  font-size: 1em;
}

.siteDesignerSection {
  font-size: 0.75em;
}

.primaryColor {
  color: #000000;
}

.secondaryColor {
  color: #d7dcdc;
}/*# sourceMappingURL=Footer.module.css.map */