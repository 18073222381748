.card {
  border: 2px #23e6ef ridge;
  box-shadow: 0 2px 8px rgba(35, 230, 239, 0.4509803922);
}

.cardRounded {
  border-radius: 0.3em;
}

.cardRoundedModerate {
  border-radius: 0.75em;
}

.cardRoundedHeavy {
  border-radius: 1em;
}

.cardHover:hover {
  box-shadow: 0 2px 8px rgba(35, 230, 239, 0.4509803922);
  color: #23e6ef;
}

.primary {
  background-color: #ffffff;
  color: #000000;
}

.altPrimary {
  background-color: rgba(20, 20, 20, 0.3647058824);
  color: #000000;
}

.none {
  background-color: transparent;
  color: #000000;
}/*# sourceMappingURL=Card.module.css.map */