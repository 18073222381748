.navbarToggler {
  font-weight: bolder;
  box-shadow: 0 1px 8px rgba(35, 230, 239, 0.4509803922);
  background-color: transparent;
}

.navbarTogglerBackground {
  background-color: #ffffff !important;
}

.navbarToggler:hover {
  border: 2px #23e6ef solid;
}/*# sourceMappingURL=Toggler.module.css.map */