@import "../../../Variables.scss";

.centered{
    margin: 4em auto 0em auto;
}
.loader{
    font-size: 2.5em;
    border: 10px solid #f2f1ff;
    border-radius: 50%;
    border-top: 10px solid $accentColorDimmed;
    width: 5em;
    height: 5em;
    animation: spin 2s linear infinite;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    box-shadow:  0px -2px 10px $accentColorDimmed;
}
.innerGlow{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    z-index: -100;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}