@import "../../../Variables.scss";

.footerWrapper {
    text-align: center;
    font-size: 1em;
}
.siteDesignerSection {
    font-size: .75em;
}
.primaryColor {
    color: $fontColor;
}
.secondaryColor {
    color: $secondaryFontColor;
}