// Color Palette
$primaryColor: #ffffff;
$altPrimaryColor: #1414145d;
$secondaryColor: #6c757d80;
$secondaryColorDimmed: #535353;
$accentColor: #23e6ef;
$accentColorDimmed: #23e6ef73;
$navLinkColor: #6c757d;
$altNavLinkColor: #50555a;

// Font Colors
$navFontColor: #ffffff;
$fontColor: #000000;
$secondaryFontColor: #d7dcdc;
$notFoundPrimaryColor: #40e6ef;
$notFoundSecondaryColor: #243ebd;

// Font Selection
$headerFont: 'Shadows Into Light Two', cursive;
$linkFont: 'Shadows Into Light Two', cursive;
$defaultFont: 'Shadows Into Light Two', cursive;

////// Site Mixins //////

// Background Colors //
@mixin htmlBodyBackground ($fileName:"", $gradient:""){
    font-family: $defaultFont;
    margin: 0;

    @if $fileName == "" and $gradient == "" {
        background-color: $secondaryColor;
    }
    @else if $fileName != "" and $gradient == "" {
        background-image: url(#{$fileName});
        background-size: cover;
        background-position: center;
    }
    @else {
        background: #{$gradient};
    }
}

// a //
@mixin borderAndDropShadow (
    $widthInPixels:1, 
    $borderStyle:"solid", 
    $boxShadowSpread:2) {
    
}