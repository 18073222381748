@import "../../../../Variables.scss";

.navigationLinks {
    font-size: 1.5em !important;
    background-color: $navLinkColor !important;
    color: $navFontColor !important;
}
.navigationLinks:hover {
    font-weight: bolder;
    background-color: $altNavLinkColor !important;
    color: $accentColor !important;
    border: 1px $accentColor solid;
}
.linkFontFamily {
    font-family: $linkFont;
}