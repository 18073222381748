@import "../../../../Variables.scss";

.logo {
    width: 260px;
    margin-right: 2em;
}
.logoAlt {
    width: 130px;
    margin-right: 2em;
}
.navbarWrapper {
    padding: .25em 1em;
}
.socialIconWrapper {
    padding: 0em !important;
    margin: 0em 0em 0em .4em !important;
    width: 3.5em !important;
}
.navigationWrapper {
    padding: .0em;
}
.navigationLinks {
    padding: .15em;
}
.navigationBarToggler {
    width: 3em;
    height: 3em;
    margin-top: 1.5em;
}